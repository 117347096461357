import http from '@/http-common-contact'


const URL = '/api/admin'

class CountryService {
  getCountries() {
    return http.get(`${URL}/get-countries`)
  }
  update(data, id) {
    return http.put(`${URL}/update-country/${id}`, data)
  }
}

export default new CountryService()