<template>
    <div class="cblock" v-for="country in countries" :key="country.id">
        <input type="text" v-model="country.name" class="form-control" @change="updateCountry(country)" />
    </div>
</template>

<script>
import CountryService from "@/services/admin/CountryService";

export default {
    data() {
        return {
            countries: []
        }
    },
    methods: {
        getCountries() {
            CountryService.getCountries().then((response) => {
                this.countries = response.data
            })
            .catch((error) => {
                this.$toast.error("Error while retreiving countries");
            });
        },
        updateCountry(country) {
            CountryService.update(country, country.id).then(() => {
                this.$toast.success("Country updated");
            }).catch(() => {
                this.$toast.error("Error while updating country");
            });
        }
    },
    mounted() {
        this.getCountries();
    }
}
</script>
<style scoped>
    .cblock{
        max-width: 600px;
        margin-bottom: 8px
    }
</style>